<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <div class="modal-header">
                <div class="modal-title">
                    <div v-if="realName">编辑薪资记录({{realName}})</div>
                    <div v-else>编辑薪资记录</div>
                </div>
            </div>
            <!--            责任制-->
            <div v-if="payConditionsCode=='1'">
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('标准月薪')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="请输入标准月薪" v-model="entity.monthlyPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('应出勤/时')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="请输入应出勤/时" v-model="entity.stdLoborHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('实际出勤/时')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="实际出勤/时" v-model="entity.factLaborHours"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('工作日缺勤/时')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="工作日缺勤/时" v-model="entity.absenceHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('周末缺勤/时')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="周末缺勤/时" v-model="entity.weekAbsence"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('法定缺勤/时')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="法定缺勤/时" v-model="entity.holidayAbsence"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('有薪假/时')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="有薪假/时" v-model="entity.paidLeaveHours"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('计薪时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="计薪时数" v-model="entity.payHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('底薪')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="底薪" v-model="entity.basePay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('主管加给')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="主管加给" v-model="entity.managerAdd"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('岗位津贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="岗位津贴" v-model="entity.psotAllowance"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('技术津贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="技术津贴" v-model="entity.skillAllowance"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('其它津贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="其它津贴" v-model="entity.otherAllowance"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('津贴小计')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="津贴小计" v-model="entity.allowanceSubtotal"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('基本工资')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="基本工资" v-model="entity.basicWage"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('预付加班工资')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="预付加班工资" v-model="entity.prepaidOtPay"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('绩效奖金')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="绩效奖金" v-model="entity.kpiPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('奖惩')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="奖惩" v-model="entity.award"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('EHS奖')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="EHS奖" v-model="entity.ehsPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('安全奖')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="安全奖" v-model="entity.securityPay"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('内部稽核奖')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="内部稽核奖" v-model="entity.auditPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('技术改善奖')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="技术改善奖" v-model="entity.skillPay"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('内荐奖')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="内荐奖" v-model="entity.recommendPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('其它奖金')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="其它奖金" v-model="entity.otherBonus"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('奖金小计')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="奖金小计" v-model="entity.bonusSubtotal"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('夜班天数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="夜班天数" v-model="entity.nightShiftDays"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('夜班补贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="夜班补贴" v-model="entity.nightShiftSubsidy"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('通讯补贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="通讯补贴" v-model="entity.communicationSubsidy"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('住宿/交通/餐补')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="住宿/交通/餐补" v-model="entity.stayTrafficMealPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('其它补贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="其它补贴" v-model="entity.otherSubsidy"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('补贴小计')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="补贴小计" v-model="entity.subsidySubtotal"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('事假时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="事假时数" v-model="entity.sjHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('事假补贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="事假补贴" v-model="entity.sjDeduction"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('病假时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="病假时数" v-model="entity.bjHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('病假扣除')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="病假扣除" v-model="entity.bjDeduction"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('旷工时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="旷工时数" v-model="entity.absentHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('旷工扣除')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="旷工扣除" v-model="entity.absentDeduction"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('迟到早退时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="迟到早退时数" v-model="entity.lateOrEarlyHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('迟到早退扣款')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="迟到早退扣款" v-model="entity.lateOrEarlyPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('缺勤扣款小计')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="缺勤扣款小计" v-model="entity.absentDeduction"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('缺勤工资')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="缺勤工资" v-model="entity.absenceWage"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('应发工资')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="应发工资" v-model="entity.shouldWage"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('社保基数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="社保基数" v-model="entity.ssPaymentBase"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('社保代扣')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="社保代扣" v-model="entity.ssWithholding"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('公积金基数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="公积金基数" v-model="entity.pfPaymentBase"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('公积金代扣')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="公积金代扣" v-model="entity.pfWithholding"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('个人所得税')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="个人所得税" v-model="entity.personTax"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('代扣代缴小计')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="代扣代缴小计" v-model="entity.withholdingSubtotal"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('公司社保成本')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="公司社保成本" v-model="entity.companySsPayment"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('公司公积金成本')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="公司公积金成本" v-model="entity.companyPfPayment"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('现金支付')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="现金支付" v-model="entity.cashPayment"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('实发工资')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="实发工资" v-model="entity.realWages"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('其它个人借记')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="其它个人借记" v-model="entity.otherPersonalLoan"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('银行转账工资')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="银行转账工资" v-model="entity.bankTransfer"/>
                    </a-col>
                </a-row>

            </div>
            <!--                非责任制-->
            <div v-else>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('标准月薪')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="请输入标准月薪" v-model="entity.monthlyPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('应出勤/时')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="请输入应出勤/时" v-model="entity.stdLoborHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('实际出勤/时')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="实际出勤/时" v-model="entity.factLaborHours"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('工作日缺勤/时')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="工作日缺勤/时" v-model="entity.absenceHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('周末缺勤/时')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="周末缺勤/时" v-model="entity.weekAbsence"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('法定缺勤/时')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="法定缺勤/时" v-model="entity.holidayAbsence"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('有薪假/时')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="有薪假/时" v-model="entity.paidLeaveHours"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('计薪时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="计薪时数" v-model="entity.payHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('底薪')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="底薪" v-model="entity.basePay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('主管加给')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="主管加给" v-model="entity.managerAdd"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('岗位津贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="岗位津贴" v-model="entity.psotAllowance"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('技术津贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="技术津贴" v-model="entity.skillAllowance"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('其它津贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="其它津贴" v-model="entity.otherAllowance"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('津贴小计')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="津贴小计" v-model="entity.allowanceSubtotal"/>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('基本工资')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="基本工资" v-model="entity.basicWage"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('绩效奖金')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="绩效奖金" v-model="entity.kpiPay"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('奖惩')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="奖惩" v-model="entity.award"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('EHS奖')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="EHS奖" v-model="entity.ehsPay"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('安全奖')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="安全奖" v-model="entity.securityPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('内部稽核奖')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="内部稽核奖" v-model="entity.auditPay"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('技术改善奖')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="技术改善奖" v-model="entity.skillPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('超产奖')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="超产奖" v-model="entity.overProducePay"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('内荐奖')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="内荐奖" v-model="entity.recommendPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('其它奖金')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="其它奖金" v-model="entity.otherBonus"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('奖金小计')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="奖金小计" v-model="entity.bonusSubtotal"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('夜班天数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="夜班天数" v-model="entity.nightShiftDays"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('夜班补贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="夜班补贴" v-model="entity.nightShiftSubsidy"/>
                    </a-col>

                    <a-col :span="4">
                        <q-label :label="l('工龄补贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="工龄补贴" v-model="entity.workAgeSubsidy"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('通讯补贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="通讯补贴" v-model="entity.communicationSubsidy"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('住宿/交通/餐补')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="住宿/交通/餐补" v-model="entity.stayTrafficMealPay"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('接机补贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="接机补贴" v-model="entity.airportPickUpPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('其它补贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="其它补贴" v-model="entity.otherSubsidy"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('补贴小计')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="补贴小计" v-model="entity.subsidySubtotal"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('事假时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="事假时数" v-model="entity.sjHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('事假补贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="事假补贴" v-model="entity.sjDeduction"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('病假时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="病假时数" v-model="entity.bjHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('病假扣除')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="病假扣除" v-model="entity.bjDeduction"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('旷工时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="旷工时数" v-model="entity.absentHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('旷工扣除')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="旷工扣除" v-model="entity.absentDeduction"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('迟到早退时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="迟到早退时数" v-model="entity.lateOrEarlyHours"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('迟到早退扣款')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="迟到早退扣款" v-model="entity.lateOrEarlyPay"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('缺勤扣款小计')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="缺勤扣款小计" v-model="entity.absentDeduction"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('工作日加班时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="工作日加班时数" v-model="entity.oT1"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('工作日加班费')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="工作日加班费" v-model="entity.oT1Pay"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('周末加班时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="周末加班时数" v-model="entity.oT2"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('周末加班费')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="周末加班费" v-model="entity.oT2Pay"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('法定加班时数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="法定加班时数" v-model="entity.oT3"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('法定加班费')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="法定加班费" v-model="entity.oT3Pay"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('加班费小计')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="加班费小计" v-model="entity.otCostSubtotal"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('差额补贴')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="差额补贴" v-model="entity.differenceSubsidy"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('缺勤工资')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="缺勤工资" v-model="entity.absenceWage"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('应发工资')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="应发工资" v-model="entity.shouldWage"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('社保基数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="社保基数" v-model="entity.ssPaymentBase"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('社保代扣')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="社保代扣" v-model="entity.ssWithholding"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('公积金基数')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="公积金基数" v-model="entity.pfPaymentBase"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('公积金代扣')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="公积金代扣" v-model="entity.pfWithholding"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('个人所得税')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="个人所得税" v-model="entity.personTax"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('代扣代缴小计')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="代扣代缴小计" v-model="entity.withholdingSubtotal"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('公司社保成本')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="公司社保成本" v-model="entity.companySsPayment"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('公司公积金成本')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="公司公积金成本" v-model="entity.companyPfPayment"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('现金支付')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="现金支付" v-model="entity.cashPayment"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('实发工资')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="实发工资" v-model="entity.realWages"/>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="4">
                        <q-label :label="l('其它个人借记')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="其它个人借记" v-model="entity.otherPersonalLoan"/>
                    </a-col>
                    <a-col :span="4">
                        <q-label :label="l('银行转账工资')"></q-label>
                    </a-col>
                    <a-col :span="8">
                        <a-input placeholder="银行转账工资" v-model="entity.bankTransfer"/>
                    </a-col>
                </a-row>
            </div>
            <!-- 按钮 -->
            <div class="modal-footer">

                <a-button @click="close">
                    <a-icon type="close-circle"/>
                    {{ l('Cancel') }}
                </a-button>
                <a-button :type="'primary'" @click="save">
                    <a-icon type="save"/>
                    保存
                </a-button>
            </div>
        </a-spin>
    </a-config-provider>

</template>

<script>
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import zh_CN from "ant-design-vue/lib/locale/zh_CN"
    import {
        CreateOrUpdateSalaryRecordInput,
        SalaryRecordEditDto,
        SalaryRecordServiceProxy
    } from "../../../../shared/service-proxies";

    export default {
        name: "edit-salary-record",
        mixins: [ModalComponentBase],
        data() {
            return {
                zh_CN,
                id: undefined,
                entity: new SalaryRecordEditDto(),
                realName: undefined,
                payConditionsCode:undefined
            }
        },
        created() {
            this.fullData();
            this._salaryRecordServiceProxy = new SalaryRecordServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            if (this.id) {
                this.getData();
            }
        },
        methods: {
            getData() {
                this.loading = true;
                this._salaryRecordServiceProxy.getForEdit(this.id)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then(res => {
                        this.entity = res.salaryRecord;
                    });
            },
            save() {
                let input = new CreateOrUpdateSalaryRecordInput();
                input.salaryRecord = this.entity;
                this.loading=true;
                this._salaryRecordServiceProxy.createOrUpdate(input)
                .finally(()=>{this.loading=false;})
                .then(res=>{
                    this.$notification['success']({
                        message: this.l('SavedSuccessfully'),
                    });
                    this.success(true);
                });
            }
        }
    }
</script>

<style scoped>

</style>
