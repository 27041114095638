<template>
    <a-spin :spinning="loading">
        <div class="modal-header">
            <div class="modal-title">
                <div>批量修改状态</div>
            </div>
        </div>
        <div>
            <a-row class="d-flex align-items-center">
                <a-col>
                    状态：
                </a-col>
                <a-col>
                    <a-select
                            placeholder="状态"
                            style="width:200px"
                            v-model="salaryRecordState">
                        <a-select-option v-for="item in salaryRecordStateList"
                                         :key="item.itemDetailCode">
                            {{item.itemDetailName}}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
        </div>
        <!-- 按钮 -->
        <div class="modal-footer">

            <a-button @click="close">
                <a-icon type="close-circle"/>
                {{ l('Cancel') }}
            </a-button>
            <a-button :type="'primary'" @click="save">
                <a-icon type="save"/>
                保存
            </a-button>
        </div>
    </a-spin>
</template>

<script>
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import {Dic} from "../../../../shared/utils";
    import {BatchUpdateStateInput, SalaryRecordServiceProxy} from "../../../../shared/service-proxies";

    export default {
        name: "batch-update-record-state",
        mixins: [ModalComponentBase],
        data(){
            return{
                salaryRecordStateList: [],
                salaryRecordState: undefined,
                ids: [],
            }
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
        },
        async mounted() {
            this.salaryRecordStateList = await Dic.getInstance().getDicAsync("QYB_SL_SalaryRecord_State");
            //去除结账状态
            if(this.salaryRecordStateList.length>0){
                this.salaryRecordStateList=[...this.salaryRecordStateList.filter(item=>item.itemDetailCode!="3")];
            }
            this._salaryRecordServiceProxy=new SalaryRecordServiceProxy(this.$apiUrl, this.$api);
        },
        methods:{
            save() {
                if (!this.salaryRecordState) {
                    return abp.message.warn('请选择状态');
                }
                const input = new BatchUpdateStateInput();
                input.ids = this.ids;
                input.state = this.salaryRecordState;
                this.loading = true;
                this._salaryRecordServiceProxy.batchUpdateRecordState(input)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then(res => {
                        this.$notification["success"]({
                            message: '修改成功',
                        });
                        this.success(true);
                    })
            }
        }
    }
</script>

<style scoped>

</style>
